import { useContext, useEffect, useState } from "react";
import CustomM from "./customM";

import mbg1 from '../assets/mbg1.jpg'
import mbg2 from '../assets/mbg2.jpg'
import SetMasurement from "./setMeasurements";
import { useNavigate } from "react-router-dom";

import aim from '../assets/samples/anima.gif'

import fImg from '../assets/samples/body.png'
import sImg from '../assets/samples/BODY1.png'
import {Types} from '../Data';

import close from '../assets/icon/close.png'
import { getData } from "../Firebase/firestoreGet";
import { db } from "../firebase";
import { AuthContext } from "../Firebase/AuthProvider";

function Measurement({edit, nav, setNav, section, setSection, typeM}) {

    const { user } = useContext(AuthContext);

    const [M, setM] = useState();

    const [useSection, setUseSection] = useState(0)
    const [useNav, setUseNav] = useState(nav)

    const [cat, setCat] = useState(2)
    
    const navigate = useNavigate()

    const [svd, setSvd] = useState()

    useEffect(()=>{
        const data =  JSON.parse( sessionStorage.getItem("sel") )

        if(data && data["category"]){
            console.log( "From check : ", Types[data["category"]]["measurement"] )
            setCat( Types[data["category"]]["measurement"] )
        }
        

        if(!data){
            navigate("/")
        }
    }, [])
    useEffect(()=>{
        if(section){
            setUseSection(section)
        }
    }, [section])
    useEffect(()=>{
        if(nav){
            setUseNav(nav)
        }
    }, [nav])

    useEffect(()=>{
        if(svd && svd.id === 1){

            let usrid = user.email
            if(!usrid){
                usrid = user.phoneNumber
            }

            const collection = db.collection('Users').doc('measurements').collection(usrid)

            getData(collection).then((data)=>{
                setSvd({ data:data , id:2})
            })
        }
    }, [svd])


    const [loading, setLoading] = useState(false)

    
    const [isMobile , setIsMobile] = useState(window.innerWidth <= 480);

    const [mState, setMState] = useState(0)

    const [picModal, setPicModal] = useState()

    // useEffect(()=>{
    //     const session = JSON.parse(sessionStorage.getItem('sel'))
    //     if(!session){
    //         navigate("/custom") 
    //     }
    // }, [])

    // function backMove () {
        
    //     if(nav === 2){setNav(1)}
        
    // }
    // useEffect(()=>{
    //     window.history.pushState( 'dummy', document.title, window.location.href )
    // }, [])
    // useEffect(()=>{
    //     if(nav === 2){window.history.pushState( 'dummy', document.title, window.location.href )

    //     window.addEventListener('popstate', backMove);
    //     console.log("someting from measurments :", nav)}

    //     return()=>{
    //         window.removeEventListener('popstate', backMove);

    //         if (window.history.state === 'dummy' && nav === 2) {
    //             window.history.back();
    //         }
        
    //     }

    // }, [])

    return ( 
        <div className="measurements"
            
            style={useSection === 0 && !isMobile ? { "top":"0" } : (isMobile && useSection === 0) ? {"top":"60px", height:"100svh"} :  {"top":"60px", height:"calc(90svh)" }}
        >
            <div
                type="button"
                className="backButton"
                style={useSection === 0 && !isMobile ? { "top":"10vh" } : isMobile ? { "top":"10px" }:{}}
                onClick={()=>{
                    
                    if(useSection === 2){
                        if(typeM){
                            setSection(0)
                        }else{
                            setUseSection(0)
                        }
                        
                    }else if(useSection === 1){
                        if(mState === 0){
                            if(typeM){
                                setSection(0)
                            }else{
                                setUseSection(0)
                            }
                        } else {
                            setMState(mState-1)
                        }
                    }
                    else{
                        if(typeM){
                            setNav(1)
                        }
                    }
                    
                }}
                
            > {"<"}  <b><u>Go Back </u></b></div>

            {picModal && !isMobile && 
            <div
                className="m-hover-img"
            >   
                <label style={{"width":"100%","padding":"5px", "background":"#14213D", "color":"white", "textAlign":"center"}}
                >
                    {picModal === 1? "Sample Front View Image" : "Sample Side View Image"}
                </label>
                <img src = { picModal === 1 ? fImg : sImg} 
                    style={{"width":"100%", "height":"100%", "objectFit":"contain"}}/>
                
            </div>

            }

            {loading && <div style={{"position":"fixed", "height":"100vh", "width":"100vw", "background":"rgb(0, 0, 0, 0.3)", "textAlign":"center", "top":"0", "left":"0", "zIndex":"1000"}}>
                <img alt src={aim} onerror="this.src='https://d1fufvy4xao6k9.cloudfront.net/images/garment/loading.gif'" 
                    style={{"zIndex":"100001", "top":"40vh", "position":"absolute"}}
                />
                
            </div>}
            { useSection == 0 ? (
                <div style={{width:"100%", display:"flex", height:"100%", textAlign:"center"}}>

                    
                    <div className="m-home-mask"></div>
                    <div className="m-home-left "
                        style={{backgroundImage:`url(${mbg1})`}}
                    >
                        <div className="m-home-body both_center input1">
                            <div className="title-font1 m-home-di">Choose From <br/>Size Chart</div>
                            <div className="txt-font1 m-home-di">Easily find your ideal fit with our <br/>detailed size chart.</div>
                            <button className="Butt1 hori_center size-but"
                                onClick={()=>{
                                    window.history.pushState( 'dummy', document.title, window.location.href )
                                    
                                    if(typeM){
                                        setSection(2)
                                    }else{
                                        setUseSection(2)
                                    }
                                    // setSection(2)
                                }}
                            >
                                <span>Preset</span></button>
                        </div>
                    </div>
                    <div className="m-home-right"
                        style={{backgroundImage:`url(${mbg2})`}}
                    >
                        <div className="m-home-body both_center input2">
                            <div className="title-font1 m-home-di">Custom Measurements</div>
                            <div className="txt-font1 m-home-di">Measuring for your perfect fit has never been easier. <br/>Follow simple steps and get Measured</div>
                            <button className="Butt1 hori_center size-but"
                                onClick={()=>{
                                    window.history.pushState( 'dummy', document.title, window.location.href )
                                    if(typeM){
                                        setSection(1)
                                    }else{
                                        setUseSection(1)
                                    }
                                    
                                    // setSection(1)
                                }}
                            >
                                <span>Custom</span></button>
                        </div>
                    </div>
                </div>
            ) 
            : useSection == 1 ?  (<CustomM 
                                    M = {M}
                                    setM = {setM}
                                    edit = {edit}
                                    setLoading = {setLoading}
                                    setPicModal = {setPicModal}

                                    mState={mState}
                                    setMState={setMState}
                                    setSvd = {setSvd}
                                    cat = {cat}
                                    />) 
            : ( <SetMasurement
                    M = {M}
                    setM = {setM}
                    edit = {edit}
                    cat = {cat}
                    />)          
            }

            {(svd && svd.id !=1 ) && 
            <div className='saved_measurments_wrapper'>

            <div className='saved_measurments both_center'
                style={ svd.data.length === 0 ? {height:"30vh"}:{}}
            >

                <img src={close} style={{position:"absolute", width:"20px", "right":"3%"}} 
                    onClick={()=>{ setSvd(null) }}
                    type = "button"
                    
                />

                <div className="txt-font1 svd_msr">Saved Measurements</div>

                <div className="svd_msr_body">

                    { svd.data.map((val, ind)=>{
                        return(
                            <div className="svd_msr_card">
                                <div> <b> {ind+1}. Name : </b> {val.name} </div>
                                <div className="svd_msr_row1"> 
                                    <label><b>  Weight : </b> {val.weight}kg</label>  
                                    <label> <b> Height : </b>  {val.height}cm  </label>  
                                    <label> <b> Age : </b>  {val.age} </label>  
                                </div>
                                <div className="" style={{display:"flex", width:"100%", gap:"5%"}}>
                                    {["upper_body", "lower_body"].map((typ)=>{

                                        return(
                                            <div style={{width:"40%"}}>
                                                <div> <b> {typ} </b> </div>
                                                {Object.keys( val[typ] ).map((item)=>{
                                                    if(val[typ][item] !=0 ){
                                                        return(<div> {item} : { val[typ][item] } </div>)
                                                    }
                                                })

                                                }

                                            </div>
                                        )


                                    })}

                                    
                                </div>
                            </div>
                        )
                    })
                        
                    }

                    { svd.data.length === 0 ? <div className="" style={{textAlign:"center"}} > You have no saved Measurements </div> : <></> }

                </div>
            </div>
            </div>
            }


        </div> 
    );
}

export default Measurement;