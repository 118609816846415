import { useEffect, useState,useRef } from 'react';
import {sizeChart} from '../Data/index'
import sizeChart1 from '../assets/measurments/SC_IN.png'
import { useNavigate } from 'react-router-dom';


import size_chart from '../assets/measurments/SIZE_CHART.svg'

function SetMasurement({cat}) {

    const navigate = useNavigate()

    const [final, setFinal] = useState(JSON.parse(sessionStorage.getItem("sel")))

    const [size, setSize] = useState({"ub":0, "lb":0})

    const [isMobile, setIsMobile] = useState(false);

    const upperBodyRef = useRef(null);
    const lowerBodyRef = useRef(null);
    const [currUnit, setcurrUnit] = useState(1)

    const renderPadding = () => <div style={{ minWidth: '50%' }} />;
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480); // Adjust the width as needed
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(()=>{
        if( Object.hasOwn(final, "measurements")  ){
            let lb = final["measurements"]["lower_body"]
            let ub = final["measurements"]["upper_body"]
            sizeChart["l"].forEach((ele, i)=>{
                if(ele === lb){lb = i}
            })
            sizeChart["u"].forEach((ele, i)=>{
                if(ele === ub){ub = i}
            })
            setSize({"ub":ub, "lb":lb})
        }
        // console.log(size)

    }, [])
    useEffect(() => {
        if (final && final.measurements) {
            let lb = final.measurements.lower_body;
            let ub = final.measurements.upper_body;
            sizeChart.l.forEach((ele, i) => {
                if (ele === lb) lb = i;
            });
            sizeChart.u.forEach((ele, i) => {
                if (ele === ub) ub = i;
            });
            setSize({ "ub": ub, "lb": lb });
        }
    }, [final]);

    useEffect(() => {
        const scrollToCenter = (parentRef, index) => {
            if (parentRef.current) {
                const selectedElement = parentRef.current.children[index];
                const parentElement = parentRef.current;
                const parentRect = parentElement.getBoundingClientRect();
                const selectedRect = selectedElement.getBoundingClientRect();

                const scrollOptions = {
                    behavior: 'smooth'
                };
                if (index === 0) {
                    parentElement.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else if (index === parentRef.current.children.length - 1) {
                    parentElement.scrollTo({
                        left: parentElement.scrollWidth - parentElement.clientWidth,
                        behavior: 'smooth',
                    });
                } else {
                    parentElement.scrollTo({
                        left: selectedElement.offsetLeft - (parentRect.width / 2) + (selectedRect.width / 2),
                        behavior: 'smooth',
                    });
                }
            }
        };

        scrollToCenter(upperBodyRef, size.ub);
        scrollToCenter(lowerBodyRef, size.lb);
    }, [size]);
    const debounce = (func, wait) => {
        let timeout;
        return function(...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };
    const handleScroll = debounce((ref, type) => {
        const parentElement = ref.current;
        const children = Array.from(parentElement.children);
        const parentRect = parentElement.getBoundingClientRect();

        let closestChildIndex = 0;
        let closestDistance = Infinity;

        children.forEach((child, index) => {
            const childRect = child.getBoundingClientRect();
            const distance = Math.abs((childRect.left + childRect.width / 2) - (parentRect.left + parentRect.width / 2));

            if (distance < closestDistance) {
                closestDistance = distance;
                closestChildIndex = index;
            }
        });

        setSize(prevSize => ({ ...prevSize, [type]: closestChildIndex }));
    }, 100);

   
    

    return ( 
    <div className="setUpMeasurements">

    <div className="s-m-body hori_center">  
        <div className='s-m-top'>
            <div className='s-m-t-left main-head'>
                <div className='txt-font preset-mob'
                    
                >Choose From Our Presets</div>
                <div className='c-m-des1 '>
                
                    Providing precise measurements ensures that you receive the best fit possible. Confused about what to measure? Our easy-to-follow chart is here to help you get it just right!
                </div>
            </div>

            <button className='Butt1 measure-butt'
                onClick={()=>{
                    sessionStorage.removeItem("sel")
                    let data = {...final}
                    if(cat === 2){
                        data["measurements"] = {
                            "type":0,
                            "lower_body":sizeChart["l"][size.lb],
                            "upper_body":sizeChart["u"][size.ub]
                        }
                    } else if(cat === 1){
                        data["measurements"] = {
                            "type":0,
                            "lower_body":sizeChart["l"][size.lb],
                        }
                    } else {
                        data["measurements"] = {
                            "type":0,
                            "upper_body":sizeChart["u"][size.ub]
                        }
                    }
                    

                    if( Object.hasOwn(data, "id") ){
                        let local = JSON.parse( localStorage.getItem("sel"))
                        local.forEach((k, i)=>{
                            if(k["id"] === data["id"]){
                                local[i] = data
                            }
                        })
                        localStorage.setItem("sel", JSON.stringify(local))

                        // console.log("updated Item")
                        
                    }else{
                    
                    
                    
                    let currC = localStorage.getItem("sel")
                    if(currC && JSON.parse(currC).length > 0){
                        let upC = JSON.parse(currC)
                        // console.log("curr",upC)
                        let c = upC[upC.length - 1]["id"] + 1
                        data["id"] = c
                        upC.push(data)
                        
                        localStorage.setItem("sel", JSON.stringify(upC))
                    }else{
                        data["id"] = 1
                        localStorage.setItem("sel", JSON.stringify([data]))
                    }
                }    
                    // console.log("data", JSON.parse(localStorage.getItem("sel")))
                
                    navigate('/cart');
                }}
            >
                <span>Proceed</span>
            </button>
        </div>

        

        <div className="s-m-main">
                    <div className="s-m-left measure-box">
                        { (cat === 0 || cat === 2) &&
                        <div className="s-m-box">
                            <div className='s-m-box1 txt-font1'>Upper Body</div>
                            <div
                                className= {'s-m-box2'}
                                // ref={upperBodyRef}
                                // onScroll={() => handleScroll(upperBodyRef, 'ub')}
                            >
                                {sizeChart.u.map((value, key) => (
                                    <div
                                        key={key}
                                        type = "button"
                                        className={key === 0  ? "s-m-b-0 left_most" : key === sizeChart.u.length - 1 ? "s-m-b-0 right_most" : "s-m-b-1"}
                                        style={key === size.ub ? { backgroundColor: "#FCA311", color: "white", zIndex:"1" } : { backgroundColor: "white", color: "black", zIndex:"1" }}
                                        onClick={() => {setSize({ ...size, ub: key }); }}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </div>
                        </div>
                        }          
                        { (cat === 1 || cat === 2) &&
                        <div className="s-m-box">
                            <div className='s-m-box1 txt-font1'>Lower Body</div>
                            <div
                                className={'s-m-box2'}
                                ref={lowerBodyRef}
                                onScroll={() => handleScroll(lowerBodyRef, 'lb')}
                            >
                                {sizeChart.l.map((value, key) => (
                                    <div
                                        key={key}
                                        className={key === 0  ? "s-m-b-0 left_most" : key === sizeChart.l.length - 1 ? "s-m-b-0 right_most" : "s-m-b-1"}
                                        style={key === size.lb ? { backgroundColor: "#FCA311", color: "white", zIndex:"1" } : { backgroundColor: "white", color: "black", zIndex:"1" }}
                                        onClick={() => setSize({ ...size, lb: key })}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </div>
                        </div>
                        }
                        <div style={{width:"100%"}}>
                        
                        <div className='hori_center units_m FIT_W'> 
                    <div
                        className= {currUnit === 1 ? 'unit_comp unit_comp_h' : 'unit_comp'}
                        onClick={()=>{
                            setcurrUnit(1)
                        }}
                    > {currUnit === 1 ? "INCHES" : "IN"} </div> 
                    <div
                        className= {currUnit === 0 ? 'unit_comp unit_comp_h' : 'unit_comp'}
                        onClick={()=>{
                            setcurrUnit(0)
                        }}
                    >{currUnit === 0 ? "CENTIMETER" : "CM"}</div> 
                </div>

                        <img src={sizeChart["img"][currUnit]} className="s-m-img"/>
                
                        </div>
                    </div>
            <div className="s-m-right">
                <img src={size_chart} className="s-m-img1"/>
                {/* <div className='hori_center units_m FIT_W'> 
                    <div
                        className= {currUnit === 1 ? 'unit_comp unit_comp_h' : 'unit_comp'}
                        onClick={()=>{
                            setcurrUnit(1)
                        }}
                    > {currUnit === 1 ? "INCHES" : "IN"} </div> 
                    <div
                        className= {currUnit === 0 ? 'unit_comp unit_comp_h' : 'unit_comp'}
                        onClick={()=>{
                            setcurrUnit(0)
                        }}
                    >{currUnit === 0 ? "CENTIMETER" : "CM"}</div> 
                </div> */}
            </div>
        </div>
        

    </div>

    </div> 
    );
}

export default SetMasurement;