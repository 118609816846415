// import back1 from '../assets/icon/s_filters/back1.svg'
// import back2 from '../assets/icon/s_filters/back2.svg'
// import back3 from '../assets/icon/s_filters/back3.svg'
// import back4 from '../assets/icon/s_filters/back4.svg'

// import lapel1 from '../assets/icon/s_filters/lapel1.svg'
// import lapel2 from '../assets/icon/s_filters/lapel2.svg'
// import lapel3 from '../assets/icon/s_filters/lapel3.svg'
// import lapel4 from '../assets/icon/s_filters/lapel4.svg'

// import style1 from '../assets/icon/s_filters/style1.svg'
// import style2 from '../assets/icon/s_filters/style2.svg'
// import style3 from '../assets/icon/s_filters/style3.svg'

// import pocket1 from '../assets/icon/s_filters/pocket1.svg'
// import pocket2 from '../assets/icon/s_filters/pocket2.svg'
// import pocket3 from '../assets/icon/s_filters/pocket3.svg'

// import fabricImg from '../assets/selectF.jpg'

// import coat from '../assets/samples/coat/red_coat.png'
// import Shirt from '../assets/samples/shirt/grey_shirt.png'
// import pant from '../assets/samples/pant/red_pant.png'
// import vest from '../assets/samples/jacket/green_jacket.png'

// import green_coat_single from '../assets/samples/coat/g1_coat_single.png'
// import red_coat_single from '../assets/samples/coat/red_coat_single.png'
// import red_coat_back from '../assets/samples/coat/red_coat_back.png'
// import green_coat_back from '../assets/samples/coat/green_coat_back.png'

// import red_jacket from '../assets/samples/jacket/red_jacket_single.png'
// import green_jacket from '../assets/samples/jacket/green_jacket_single.png'

// import grey_shirt from '../assets/samples/shirt/grey_shirt_single.png'
// import pink_shirt from '../assets/samples/shirt/pink_shirt_single.png'

// import black_pant from '../assets/samples/pant/black_pant_single.png'
// import green_pant from '../assets/samples/pant/green_pant_single.png'

// import gcoat from '../assets/samples/coat/green_coat.png'
// import pShirt from '../assets/samples/shirt/orange_shirt.png'
// import gpant from '../assets/samples/pant/green_pant.png'
// import vest1 from '../assets/samples/jacket/red_jacket.png'

import sChart_in from "../assets/measurments/SC_IN.png";
import sChart_cm from "../assets/measurments/SC_CM.png";


// import {ReactComponent as one_button } from '../assets/SVG/SINGLE BUTTON-01.svg'
import one_button from "../assets/SVG/SINGLE BUTTON-01.svg";
import two_button from "../assets/SVG/double button-01.svg";
import three_button from "../assets/SVG/three button-01.svg";
import four_button from "../assets/SVG/four button-01.svg";
import six_button from "../assets/SVG/six button-01.svg";

import j_round from "../assets/SVG/ROUND-01.svg";
import j_semiround from "../assets/SVG/semi round-01.svg";
import j_straight from "../assets/SVG/STRAIGHT-01.svg";

import Notch from "../assets/SVG/Notch.svg";
import peak from "../assets/SVG/PEAK.svg";

import flap from "../assets/SVG/FLAP.svg";
import patch from "../assets/SVG/PATCH.svg";

import non_vent from "../assets/SVG/NON VENT.svg";
import s_vent from "../assets/SVG/SINGLE VENT.svg";
import side_vent from "../assets/SVG/SIDE VENT.svg";

import non_plates from "../assets/SVG/NON PLATES.svg";
import s_plates from "../assets/SVG/SINGLE PLATE.svg";
import d_plates from "../assets/SVG/DOUBLE PLATES.svg";

import back_pocket from "../assets/SVG/SINGLE POCKET.svg";
import d_pocket from "../assets/SVG/DOUBLE POCKET.svg";

import s_brested from "../assets/SVG/SINGLE BRESTED.svg";
import d_brested from "../assets/SVG/DOUBLE BRESTED.svg";

import semi_spread from "../assets/SVG/SEMI SPREAD.svg";
import spread from "../assets/SVG/SPREAD.svg";

import semi_spread_n from "../assets/SVG/SPREAD_n.svg";

import button_down from "../assets/SVG/BUTTON DOWN.svg";
import mand from "../assets/SVG/MANDARIN.svg";

import square from "../assets/SVG/SQUARE.svg";
import ROUND from "../assets/SVG/ROUND.svg";
import NOTCH from "../assets/SVG/NOTCH 1.svg";

import square2 from "../assets/SVG/2SQUARE.svg";
import ROUND2 from "../assets/SVG/2ROUND.svg";
import NOTCH2 from "../assets/SVG/2NOTCH.svg";

import CUFFLINKS from "../assets/SVG/CUFFLINKS.svg";

import shirt_pocket_no from "../assets/SVG/NO POCKET.svg";
import shirt_pocket_1 from "../assets/SVG/POCKET.svg";

import P1 from "../assets/suits/product-1.jpg";
import P2 from "../assets/suits/uniform.jpg";
import P3 from "../assets/suits/pant_shirt.jpg";

export const sizeChart = {
  u: [36, 38, 40, 42, 44, 46, 48, 50, 52],
  l: [30, 32, 34, 36, 38, 40, 42, 44, 46],
  img: [sChart_cm, sChart_in],

};
export const fabrics = [
  { fabric: "Blue Wool", brand: "Raymond", price: "1199" },
  { fabric: "Black Wool", brand: "Raymond1", price: "1200" },
  { fabric: "Green Wool", brand: "Raymond2", price: "1199" },
  { fabric: "Red Wool", brand: "Raymond3", price: "1199" },
  { fabric: "Silk", brand: "Raymond4", price: "1199" },
  { fabric: "Cotton", brand: "Raymond5", price: "1199" },
  { fabric: "Synthetic", brand: "Raymond6", price: "1199" },
  { fabric: "Nylon", brand: "Raymond7", price: "1199" },
  { fabric: "Party", brand: "Raymond8", price: "1199" },
  { fabric: "Sample", brand: "Raymond9", price: "1199" },
];

export const Types = { 
  suit : {
    Coat: 0, Shirt: 4, Pant: 1, Vest: 2,
    measurement : 2
  },
  pant : {
    Pant : 0,
    measurement : 1
  },
  shirt:{
    Shirt : 0,
    measurement : 0
  },
  formal : {
    Shirt: 1, Pant:0,
    measurement : 2
  },
  blazer:{
    Coat : 0,
    measurement : 0
  }
};

// export const dummyImg  = {
//     "suits":{
//         "Shirt":Shirt,
//         "Pant":gpant,
//     }
// }

// export const Fabrics_all = {
//     "Coat":[
//         {"fabric": "Blue Wool", "brand":"Raymond", "price":"1000"
//             ,"src":coat, "fabricIMG":fabricImg, "single" : [red_coat_single, red_coat_back]
//         },
//         {"fabric": "Green Wool", "brand":"Ray1", "price":"1200"
//             ,"src":gcoat, "fabricIMG":fabricImg, "single" : [green_coat_single, green_coat_back]
//         },
//         {"fabric": "Green Wool", "brand":"Raymond2", "price":"1199"
//             ,"src":gcoat, "fabricIMG":fabricImg, "single" : [green_coat_single, green_coat_back]
//         },
//         {"fabric": "Red Wool", "brand":"Raymond3", "price":"1199"
//             ,"src":gcoat, "fabricIMG":fabricImg, "single" : [green_coat_single, green_coat_back]
//         },
//         {"fabric": "Silk", "brand":"Raymond4", "price":"1199"
//             ,"src":gcoat, "fabricIMG":fabricImg, "single" : [green_coat_single, green_coat_back]
//         },
//         {"fabric": "Cotton", "brand":"Raymond5", "price":"1199"
//             ,"src":gcoat, "fabricIMG":fabricImg, "single" : [green_coat_single, green_coat_back]
//         },
//         {"fabric": "Synthetic", "brand":"Raymond6", "price":"1199"
//             ,"src":gcoat, "fabricIMG":fabricImg, "single" : [green_coat_single, green_coat_back]
//         }
//     ],
//     "Pant":[
//         {"fabric": "Blue Wool", "brand":"Raymond", "price":"1000"
//             ,"src":pant, "fabricIMG":fabricImg, "single" : [black_pant]
//         },
//         {"fabric": "Green Wool", "brand":"Raymond1", "price":"1200"
//             ,"src":gpant, "fabricIMG":fabricImg, "single" : [green_pant]
//         },
//         {"fabric": "Green Wool", "brand":"Raymond2", "price":"1199"
//             ,"src":gpant, "fabricIMG":fabricImg, "single" : [green_pant]
//         },
//         {"fabric": "Red Wool", "brand":"Raymond3", "price":"1199"
//             ,"src":gpant, "fabricIMG":fabricImg, "single" : [green_pant]
//         },
//         {"fabric": "Silk", "brand":"Raymond4", "price":"1199"
//             ,"src":gpant, "fabricIMG":fabricImg, "single" : [green_pant]
//         },
//         {"fabric": "Cotton", "brand":"Raymond5", "price":"1199"
//             ,"src":gpant, "fabricIMG":fabricImg, "single" : [green_pant]
//         },
//         {"fabric": "Synthetic", "brand":"Raymond6", "price":"1199"
//             ,"src":gpant, "fabricIMG":fabricImg, "single" : [green_pant]
//         }
//     ],
//     "Shirt":[
//         {"fabric": "Blue Wool", "brand":"Raymond", "price":"1000"
//             ,"src":Shirt, "fabricIMG":fabricImg,  "single" : [grey_shirt]
//         },
//         {"fabric": "Green Wool", "brand":"Raymond1", "price":"1200"
//             ,"src":pShirt, "fabricIMG":fabricImg,  "single" : [pink_shirt]
//         },
//         {"fabric": "Green Wool", "brand":"Raymond2", "price":"1199"
//             ,"src":pShirt, "fabricIMG":fabricImg,  "single" : [grey_shirt]
//         },
//         {"fabric": "Red Wool", "brand":"Raymond3", "price":"1199"
//             ,"src":pShirt, "fabricIMG":fabricImg,  "single" : [grey_shirt]
//         },
//         {"fabric": "Silk", "brand":"Raymond4", "price":"1199"
//             ,"src":pShirt, "fabricIMG":fabricImg,  "single" : [grey_shirt]
//         },
//         {"fabric": "Cotton", "brand":"Raymond5", "price":"1199"
//             ,"src":pShirt, "fabricIMG":fabricImg,  "single" : [grey_shirt]
//         },
//         {"fabric": "Synthetic", "brand":"Raymond6", "price":"1199"
//             ,"src":pShirt, "fabricIMG":fabricImg,  "single" : [grey_shirt]
//         }
//     ],
//     "Vest":[
//         {"fabric": "Blue Wool", "brand":"Raymond", "price":"1000"
//             ,"src":vest, "fabricIMG":fabricImg,  "single" : [green_jacket]
//         },
//         {"fabric": "Green Wool", "brand":"Raymond1", "price":"1200"
//             ,"src":vest1, "fabricIMG":fabricImg,  "single" : [red_jacket]
//         },
//         {"fabric": "Green Wool", "brand":"Raymond2", "price":"1199"
//             ,"src":vest, "fabricIMG":fabricImg,  "single" : [green_jacket]
//         },
//         {"fabric": "Red Wool", "brand":"Raymond3", "price":"1199"
//             ,"src":vest1, "fabricIMG":fabricImg,  "single" : [green_jacket]
//         },
//         {"fabric": "Silk", "brand":"Raymond4", "price":"1199"
//             ,"src":vest, "fabricIMG":fabricImg,  "single" : [green_jacket]
//         },
//         {"fabric": "Cotton", "brand":"Raymond5", "price":"1199"
//             ,"src":vest1, "fabricIMG":fabricImg,  "single" : [green_jacket]
//         },
//         {"fabric": "Synthetic", "brand":"Raymond6", "price":"1199"
//             ,"src":vest, "fabricIMG":fabricImg,  "single" : [green_jacket]
//         }
//     ]
// }

export const fabricFilter = [
  {
    type: "Pattern",
    q: 0,
    attr: [
      { name: "Solid", value: "#007BA7" },
      { name: "Striped", value: "#FFBF00" },
      { name: "Checked", value: "#007BA7" },
      { name: "Solid", value: "#FFBF00" },
      { name: "Block", value: "#007BA7" },
      { name: "Plain", value: "#FFBF00" },
    ],
  },
  {
    type: "Color",
    q: 1,
    attr: [
      { name: "Cerulean", value: "#007BA7" },
      { name: "Amber", value: "#FFBF00" },
      { name: "Crimson", value: "#DC143C" },
      { name: "Mauve", value: "#E0B0FF" },
      { name: "Teal", value: "#008080" },
      { name: "Coral", value: "#FF7F50" },
      { name: "Turquoise", value: "#40E0D0" },
      { name: "Chartreuse", value: "#7FFF00" },
      { name: "Periwinkle", value: "#CCCCFF" },
      { name: "Saffron", value: "#F4C430" },
      { name: "Lavender", value: "#E6E6FA" },
      { name: "Magenta", value: "#FF00FF" },
    ],
  },
  {
    type: "Material",
    q: 0,
    attr: [
      { name: "Wool", value: "#E6E6FA" },
      { name: "Linen", value: "#FF00FF" },
      { name: "Cotton", value: "#E6E6FA" },
      { name: "Silk", value: "#FF00FF" },
    ],
  },
  {
    type: "Fabric Type",
    q: 0,
    attr: [
      { name: "Wool", value: "#E6E6FA" },
      { name: "Linen", value: "#FF00FF" },
      { name: "Cotton", value: "#E6E6FA" },
      { name: "Silk", value: "#FF00FF" },
    ],
  },
  {
    type: "Sort Price",
    q: 2,
    attr: [
      { name: "Wool", value: "#E6E6FA" },
      { name: "Linen", value: "#FF00FF" },
      { name: "Cotton", value: "#E6E6FA" },
      { name: "Silk", value: "#FF00FF" },
    ],
  },
];

export const StylesFilter = [
  {
    type: "Single Brested",
    name: "Coat",
    pos: "F",
    n: 0,
    place: "Coat",
    attr: [
      [
        { name: "Single Button", img: one_button, value: "1B" },
        { name: "Double Button", img: two_button, value: "2B" },
        { name: "Three Button", img: three_button, value: "3B" },
      ],
    ],
  },
  {
    type: "Double Brested",
    name: "Coat",
    pos: "F",
    n: 0,
    attr: [
      [
        { name: "Four Button", img: four_button, value: "4B" },
        { name: "Six Button", img: six_button, value: "6B" },
      ],
    ],
  },
  {
    type: "Jodhpuri",
    name: "Coat",
    pos: "F",
    n: 0,
    attr: [
      [
        { name: "Semi Round", img: j_semiround, value: "JH" },
        { name: "Round", img: j_round, value: "JR" },
        { name: "Straight", img: j_straight, value: "JS" },
      ],
    ],
  },
  {
    type: "Back Style",
    name: "Coat",
    pos: "B",
    n: 0,
    attr: [
      [
        { name: "Non Vent", img: non_vent, value: "NV" },
        { name: "Single Vent", img: s_vent, value: "1V" },
        { name: "Side Vent", img: side_vent, value: "2V" },
      ],
    ],
  },
  {
    type: "Lapel",
    name: "Coat",
    pos: "F",
    n: 1,
    attr: [
      [
        { name: "Notch", img: Notch, value: "N" },
        { name: "Peak", img: peak, value: "P" },
      ],
    ],
  },
  {
    type: "Pocket",
    name: "Coat",
    pos: "F",
    n: 2,
    attr: [
      [
        { name: "Flap", img: flap, value: "FL" },
        { name: "Patch", img: patch, value: "PT" },
      ],
    ],
  },
  {
    type: "Style",
    name: "Pant",
    pos: "F",
    n: 0,
    place: "Pant",
    attr: [
      [
        { name: "Non Plates", img: non_plates, value: "F-NP" },
        { name: "Single Plates", img: s_plates, value: "F-SP" },
        { name: "Double Plates", img: d_plates, value: "F-DP" },
      ],
    ],
  },
  {
    type: "Back Pocket",
    name: "Pant",
    pos: "B",
    n: 0,
    attr: [
      [
        { name: "Single Pocket", img: back_pocket, value: "B-SP" },
        { name: "Double Pocket", img: d_pocket, value: "B-DP" },
      ],
    ],
  },

  {
    type: "Collar",
    name: "Shirt",
    pos: "F",
    n: 2,
    place: "Shirt",
    attr: [
      [
        { name: "Standard", img: semi_spread, value: "SD" },
        { name: "Semi Spread", img: semi_spread_n, value: "SS" },
        { name: "Spread", img: spread, value: "S0" },
      ],
      [
        { name: "Button Down", img: button_down, value: "BD" },
        { name: "Mandarin", img: mand, value: "0" },
      ],
    ],
  },
  {
    type: "Cuffs",
    name: "Shirt",
    pos: "F",
    n: 4,
    attr: [
      [
        { name: "Square", img: square, value: "S" },
        { name: "Round", img: ROUND, value: "R" },
        { name: "Notch", img: NOTCH, value: "N" },
      ],
      [
        { name: "2 Button Square", img: square2, value: "X" },
        { name: "2 Button Round", img: ROUND2, value: "Y" },
        { name: "2 Button Notch", img: NOTCH2, value: "Z" },
      ],
      [{ name: "Cufflinks", img: CUFFLINKS, value: "C" }],
    ],
  },
  {
    type: "Pocket",
    name: "Shirt",
    pos: "F",
    n: 1,
    attr: [
      [
        { name: "No Pocket", img: shirt_pocket_no, value: "0" },
        { name: "1 Pocket", img: shirt_pocket_1, value: "P1" },
      ],
    ],
  },
  {
    type: "Style",
    name: "Vest",
    pos: "F",
    n: 0,
    place: "Vest Coat",
    attr: [
      [
        { name: "Single Brested", img: s_brested, value: "SB" },
        { name: "Double Brested", img: d_brested, value: "DB" },
      ],
    ],
  },
];

export const StylesFilterMob = [
  {
    type: "Single Brested",
    name: "Coat",
    pos: "F",
    n: 0,
    place: "Coat",
    attr: [
      [
        { name: "Single Button", img: one_button, value: "1B" },
        { name: "Double Button", img: two_button, value: "2B" },
      ],

      [{ name: "Three Button", img: three_button, value: "3B" }],
    ],
  },
  {
    type: "Double Brested",
    name: "Coat",
    pos: "F",
    n: 0,
    attr: [
      [
        { name: "Four Button", img: four_button, value: "4B" },
        { name: "Six Button", img: six_button, value: "6B" },
      ],
    ],
  },
  {
    type: "Jodhpuri",
    name: "Coat",
    pos: "F",
    n: 0,
    attr: [
      [
        { name: "Semi Round", img: j_semiround, value: "JH" },
        { name: "Round", img: j_round, value: "JR" },
      ],
      [{ name: "Straight", img: j_straight, value: "JS" }],
    ],
  },
  {
    type: "Back Style",
    name: "Coat",
    pos: "B",
    n: 0,
    attr: [
      [
        { name: "Non Vent", img: non_vent, value: "NV" },
        { name: "Single Vent", img: s_vent, value: "1V" },
      ],
      [{ name: "Side Vent", img: side_vent, value: "2V" }],
    ],
  },
  {
    type: "Lapel",
    name: "Coat",
    pos: "F",
    n: 1,
    attr: [
      [
        { name: "Notch", img: Notch, value: "N" },
        { name: "Peak", img: peak, value: "P" },
      ],
    ],
  },
  {
    type: "Coat Pocket",
    name: "Coat",
    pos: "F",
    n: 2,
    attr: [
      [
        { name: "Flap", img: flap, value: "FL" },
        { name: "Patch", img: patch, value: "PT" },
      ],
    ],
  },
  {
    type: "Plates",
    name: "Pant",
    pos: "F",
    n: 0,
    place: "Pant",
    attr: [
      [
        { name: "Non Plates", img: non_plates, value: "F-NP" },
        { name: "Single Plates", img: s_plates, value: "F-SP" },
      ],
      [{ name: "Double Plates", img: d_plates, value: "F-DP" }],
    ],
  },
  {
    type: "Back Pocket",
    name: "Pant",
    pos: "B",
    n: 0,
    attr: [
      [
        { name: "Single Pocket", img: back_pocket, value: "B-SP" },
        { name: "Double Pocket", img: d_pocket, value: "B-DP" },
      ],
    ],
  },

  {
    type: "Shirt Collar",
    name: "Shirt",
    pos: "F",
    n: 2,
    place: "Shirt",
    attr: [
      [
        { name: "Standard", img: semi_spread, value: "SD" },
        { name: "Semi Spread", img: semi_spread_n, value: "SS" },
      ],
      [
        { name: "Spread", img: spread, value: "S0" },
        { name: "Button Down", img: button_down, value: "BD" },
      ],
      [{ name: "Mandarin", img: mand, value: "0" }],
    ],
  },
  {
    type: "Shirt Cuffs",
    name: "Shirt",
    pos: "F",
    n: 4,
    attr: [
      [
        { name: "Square", img: square, value: "S" },
        { name: "Round", img: ROUND, value: "R" },
      ],
      [
        { name: "Notch", img: NOTCH, value: "N" },
        { name: "2 Button Square", img: square2, value: "X" },
      ],
      [
        { name: "2 Button Round", img: ROUND2, value: "Y" },
        { name: "2 Button Notch", img: NOTCH2, value: "Z" },
      ],
      [{ name: "Cufflinks", img: CUFFLINKS, value: "C" }],
    ],
  },
  {
    type: "Shirt Pocket",
    name: "Shirt",
    pos: "F",
    n: 1,
    attr: [
      [
        { name: "No Pocket", img: shirt_pocket_no, value: "0" },
        { name: "1 Pocket", img: shirt_pocket_1, value: "P1" },
      ],
    ],
  },
  {
    type: "Vest Coat Style",
    name: "Vest",
    pos: "F",
    n: 0,
    place: "Vest",
    attr: [
      [
        { name: "Single Brested", img: s_brested, value: "SB" },
        { name: "Double Brested", img: d_brested, value: "DB" },
      ],
    ],
  },
];

///// only for formal

export const StylesFilter_formal = [
  {
    type: "Collar",
    name: "Shirt",
    pos: "F",
    n: 2,
    place: "Shirt",
    attr: [
      [
        { name: "Standard", img: semi_spread, value: "SD" },
        { name: "Semi Spread", img: semi_spread_n, value: "SS" },
        { name: "Spread", img: spread, value: "S0" },
      ],
      [
        { name: "Button Down", img: button_down, value: "BD" },
        { name: "Round", img: button_down, value: "R0" },
        { name: "Mandarin", img: mand, value: "0" },
      ],
    ],
  },
  {
    type: "Cuffs",
    name: "Shirt",
    pos: "F",
    n: 4,
    attr: [
      [
        { name: "Square", img: square, value: "S" },
        { name: "Round", img: ROUND, value: "R" },
        { name: "Notch", img: NOTCH, value: "N" },
      ],
      [
        { name: "2 Button Square", img: square2, value: "X" },
        { name: "2 Button Round", img: ROUND2, value: "Y" },
        { name: "2 Button Notch", img: NOTCH2, value: "Z" },
      ],
      [{ name: "Cufflinks", img: CUFFLINKS, value: "C" }],
    ],
  },
  {
    type: "Pocket",
    name: "Shirt",
    pos: "F",
    n: 1,
    attr: [
      [
        { name: "No Pocket", img: shirt_pocket_no, value: "0" },
        { name: "1 Pocket", img: shirt_pocket_1, value: "P1" },
      ],
    ],
  },
  {
    type: "Style",
    name: "Shirt",
    pos: "B",
    n: 0,
    attr: [
      [
        { name: "Side Cut", img: shirt_pocket_no, value: "K" },
        { name: "Apple", img: shirt_pocket_1, value: "A" },
        { name: "Straight", img: shirt_pocket_1, value: "B" },
      ],
    ],
  },
  {
    type: "Sleeve",
    name: "Shirt",
    pos: "F",
    n: 3,
    attr: [
      [
        { name: "Full Sleevel", img: shirt_pocket_no, value: "F" },
        { name: "Half Sleeve", img: shirt_pocket_1, value: "H" },  
      ],
    ],
  },
  {
    type: "Style",
    name: "Pant",
    pos: "F",
    n: 0,
    place: "Pant",
    attr: [
      [
        { name: "Non Plates", img: non_plates, value: "F-NP" },
        { name: "Single Plates", img: s_plates, value: "F-SP" },
        { name: "Double Plates", img: d_plates, value: "F-DP" },
      ],
    ],
  },
  
  {
    type: "Front Pocket",
    name: "Pant",
    pos: "F",
    n: 1,
    attr: [
      [
        { name: "Cross Pocket", img: non_plates, value: "C" },
        { name: "Round Pocket", img: s_plates, value: "R" },
      ],
    ],
  },
  {
    type: "Belt Style",
    name: "Pant",
    pos: "F",
    n: 2,
    attr: [
      [
        { name: "Long Belt", img: non_plates, value: "L" },
        { name: "Hook", img: s_plates, value: "H" },
        { name: "Gurkha", img: s_plates, value: "G" },
      ],
      [
        { name: "Cut Belt", img: non_plates, value: "C" }
      ]
    ],
  },

  {
    type: "Back Pocket",
    name: "Pant",
    pos: "B",
    n: 0,
    attr: [
      [
        { name: "Single Pocket Flap", img: back_pocket, value: "B-F1" },
        { name: "Double Pocket Flap", img: d_pocket, value: "B-F2" },
        { name: "Double Bone-1", img: d_pocket, value: "B-SP" }
      ],
      [
        { name: "Double Bone-2", img: back_pocket, value: "B-DP" },
        { name: "Single Bone-1", img: d_pocket, value: "B-S1" },
        { name: "Single Bone-2", img: d_pocket, value: "B-S2" }
      ],
      [
        { name: "No Pocket", img: back_pocket, value: "B-NP" },
      ]
    ],
  },


];

export const StylesFilterMob_formal = [
  {
    type: "Shirt Collar",
    name: "Shirt",
    pos: "F",
    n: 2,
    place: "Shirt",
    attr: [
      [
        { name: "Standard", img: semi_spread, value: "SD" },
        { name: "Semi Spread", img: semi_spread_n, value: "SS" },
      ],
      [
        { name: "Spread", img: spread, value: "S0" },
        { name: "Button Down", img: button_down, value: "BD" },
      ],
      [ { name: "Round", img: button_down, value: "R0" },
        { name: "Mandarin", img: mand, value: "0" }],
    ],
  },
  {
    type: "Shirt Cuffs",
    name: "Shirt",
    pos: "F",
    n: 4,
    attr: [
      [
        { name: "Square", img: square, value: "S" },
        { name: "Round", img: ROUND, value: "R" },
      ],
      [
        { name: "Notch", img: NOTCH, value: "N" },
        { name: "2 Button Square", img: square2, value: "X" },
      ],
      [
        { name: "2 Button Round", img: ROUND2, value: "Y" },
        { name: "2 Button Notch", img: NOTCH2, value: "Z" },
      ],
      [{ name: "Cufflinks", img: CUFFLINKS, value: "C" }],
    ],
  },
  {
    type: "Shirt Pocket",
    name: "Shirt",
    pos: "F",
    n: 1,
    attr: [
      [
        { name: "No Pocket", img: shirt_pocket_no, value: "0" },
        { name: "1 Pocket", img: shirt_pocket_1, value: "P1" },
      ],
    ],
  },
  {
    type: "Style",
    name: "Shirt",
    pos: "B",
    n: 0,
    attr: [
      [
        { name: "Side Cut", img: shirt_pocket_no, value: "K" },
        { name: "Apple", img: shirt_pocket_1, value: "A" },
      ],
      [{ name: "Straight", img: shirt_pocket_1, value: "B" }]
    ],
  },
  {
    type: "Sleeve",
    name: "Shirt",
    pos: "F",
    n: 3,
    attr: [
      [
        { name: "Full Sleevel", img: shirt_pocket_no, value: "F" },
        { name: "Half Sleeve", img: shirt_pocket_1, value: "H" }, 
      ],
    ],
  },
  {
    type: "Plates",
    name: "Pant",
    pos: "F",
    n: 0,
    place: "Pant",
    attr: [
      [
        { name: "Non Plates", img: non_plates, value: "F-NP" },
        { name: "Single Plates", img: s_plates, value: "F-SP" },
      ],
      [{ name: "Double Plates", img: d_plates, value: "F-DP" }],
    ],
  },
  
  {
    type: "Front Pocket",
    name: "Pant",
    pos: "F",
    n: 1,
    attr: [
      [
        { name: "Cross Pocket", img: non_plates, value: "C" },
        { name: "Round Pocket", img: s_plates, value: "R" },
      ],
    ],
  },
  {
    type: "Belt Style",
    name: "Pant",
    pos: "F",
    n: 2,
    attr: [
      [
        { name: "Long Belt", img: non_plates, value: "L" },
        { name: "Hook", img: s_plates, value: "H" },
        
      ],
      [ 
        { name: "Gurkha", img: s_plates, value: "G" },
        { name: "Cut Belt", img: non_plates, value: "C" }
      ]
    ],
  },
  {
    type: "Back Pocket",
    name: "Pant",
    pos: "B",
    n: 0,
    attr: [
      [
        { name: "Single Pocket Flap", img: back_pocket, value: "B-F1" },
        { name: "Double Pocket Flap", img: d_pocket, value: "B-F2" },
        
      ],
      [
        { name: "Double Bone-1", img: d_pocket, value: "B-SP" },
        { name: "Double Bone-2", img: back_pocket, value: "B-DP" },
      ],
      [
        { name: "Single Bone-1", img: d_pocket, value: "B-S1" },
        { name: "Single Bone-2", img: d_pocket, value: "B-S2" }
      ],
      [
        { name: "No Pocket", img: back_pocket, value: "B-NP" },
      ]
    ],
  },
  
];


export const defaultStyle = {
  Coat: {
    F: ["1B", "N", "FL"],
    B: ["NV"],
  },
  Pant: {
    F: ["F-NP"],
    B: ["B-SP"],
  },
  Vest: {
    F: ["SB"],
  },
  Shirt: {
    F: ["BASE", "0", "0", "C", "SLEEV"],
  },
};

export const PreFilter = [
  {
    name: "Clothing",
    items: [
      "Suits",
      "Shirt",
      "Uniform",
      "Vest",
      "Formal Wear",
      "Jodhpuri",
      "Blazer",
      "Kurtas",
      "Sharwani",
      "Pant",
    ],
  },
];

export const Sample_preset = [
  {
    name: "Wedding Tux",
    image: [P1, P2],
    price: "11999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Women Formals",
    image: [P2, P1],
    price: "120",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Blue Suede",
    image: [P3, P2],
    price: "10999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Wedding Tux",
    image: [P1, P3],
    price: "9999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Women Formals",
    image: [P2, P3],
    price: "8999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Blue Suede",
    image: [P3, P2],
    price: "7999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Wedding Tux",
    image: [P1, P2],
    price: "6999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Wedding Tux",
    image: [P1, P2],
    price: "9999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },

  {
    name: "atk Tux",
    image: [P1, P2],
    price: "11999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "atk Formals",
    image: [P2, P1],
    price: "120",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "atk Suede",
    image: [P3, P2],
    price: "10999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "atk Tux",
    image: [P1, P3],
    price: "9999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "atk Formals",
    image: [P2, P3],
    price: "8999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "atk Suede",
    image: [P3, P2],
    price: "7999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "atk Tux",
    image: [P1, P2],
    price: "6999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "atk Tux",
    image: [P1, P2],
    price: "9999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Wedding Tux",
    image: [P1, P2],
    price: "11999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Women Formals",
    image: [P2, P1],
    price: "120",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Blue Suede",
    image: [P3, P2],
    price: "10999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Wedding Tux",
    image: [P1, P3],
    price: "9999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Women Formals",
    image: [P2, P3],
    price: "8999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Blue Suede",
    image: [P3, P2],
    price: "7999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Wedding Tux",
    image: [P1, P2],
    price: "6999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
  {
    name: "Wedding Tux",
    image: [P1, P2],
    price: "9999",
    des: "weionfoierwnfio",
    fabric: "Cotton",
    brand: "Raymond",
    type: 0,
  },
];