import { useEffect, useState, useRef } from 'react';
import { 
    StylesFilter, 
    StylesFilterMob,
    StylesFilter_formal, 
    StylesFilterMob_formal } from '../../Data/index';

import { getDatafromDoc } from '../../Firebase/firestoreGet';
import { db } from '../../firebase';
import { ReactSVG } from "react-svg";

import '../svg_s.css'
import { checkCategory } from '../../Functions';

function StyleP({ category ,sel, setNav, setLoad, menuOpen, setMenuOpen, getStyleImg, setImgState , setIndication, setJodh , jodh}) {
    const [on, setOn] = useState(1);
    const sidebarRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
    const [isExpanded, setIsExpanded] = useState(false);

    const [useFilter, setUseFilter] = useState(
        category === "suit" ? StylesFilter
        : category === "formal" || category === "pant" || category === "shirt" ? StylesFilter_formal
        : StylesFilter
    )
    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };
    
    // useEffect(()=>{
        
    //     getStyleImg().then(()=>{

    //     }).catch((e)=>{
    //         // console.log("errro from default load", e)
    //     })
    // }, [])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 480);
        };

        if(window.innerWidth < 480){

            setUseFilter(
                category === "suit" ? StylesFilterMob
                : category === "formal" || category === "pant" || category === "shirt" ? StylesFilterMob_formal
                : StylesFilterMob   
            )
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window.innerWidth]);

    

    return (
        <div>
            <div className={`styleSection ${isMobile ? (menuOpen ? 'show' : 'hidden') : ''}`} ref={sidebarRef}>
                {isMobile && (
                    <div>
                        
                        <span className="close-sidebar" onClick={() => setMenuOpen(false)}>&times;</span>
                    </div>
                )}
                <div className='s-filter-wrapper'>
                    {useFilter && useFilter.map((value, key) => (
                        <>
                            {sel && sel.types && sel.types.includes(value.name) && (
                                <div className={`s-filter-body button3-50'}`}
                                    style={jodh && value.type ==="Lapel" ? {opacity:"0.5"}:{}}
                                >
                                    {/* <label
                                        style={{"padding":"3px 8px", "background":"#14213D", "color":"white","width":"fit-content", "fontSize":"0.7rem", "marginBottom":"0", "borderRadius":"5px"}}
                                    >Coat </label> */}
                                    { value["place"] && <div
                                        style={{"padding":"3px 8px", "background":"#14213D", "color":"white", "marginBottom":"10px", "borderRadius":"5px", "textAlign":"center"}}
                                    >{value["place"]} </div>}
                                    <div className='txt-font'>{value.type}</div>
                                    <div className={`s-filter-type button-50'}`}>
                                        {value.attr.map((val, k) => (
                                            <div className={`s-filter-row button2-50'}`} key={k}>
                                                {val.map((val1, k1) => (
                                                    <div
                                                        className={(sel.style?.[value.name]?.[value.pos]?.[value.n] === val1.value) ? 's-f-card-h' 
                                                            : (jodh && value["type"]=== "Lapel") || (jodh && value["type"] === "Front Pocket" && val1.value === "R") || (value["type"] === "Belt Style" && val1.value === "G" && sel.style.Pant && sel.style.Pant.F[0] === "F-NP")  ? 'dis_hov s-f-card' 
                                                            : 's-f-card'}
                                                        onClick={async () => {
                                                            if (on === 1) {
                                                                if ( ( (jodh && value["type"]=== "Lapel") || (jodh && value["type"] === "Front Pocket" && val1.value === "R") || (value["type"] === "Belt Style" && val1.value === "G" && sel.style.Pant && sel.style.Pant.F[0] === "F-NP")
                                                                ) || sel.style?.[value.name]?.[value.pos]?.[value.n] === val1.value) {
                                                                    console.log("Selecting same thing again");
                                                                } else {

                                                                    if(value["name"] === "Coat" && val1["value"][0] === "J"){
                                                                        setIndication(true)
                                                                        setJodh(true)
                                                                        // console.log("Setting Jodh")
                                                                    } else if(value["name"] === "Pant" && (val1["value"] === "F-SP" || val1["value"] === "F-DP") ){
                                                                        setJodh(true)
                                                                    }
                                                                    else{
                                                                        setJodh(false)
                                                                    }
                                                                    
                                                                    let arr = sel["style"][value["name"]][value["pos"]]
                                                                    arr[value["n"]] = val1["value"]

                                                                    

                                                                    let obj = {...sel}
                                                                    obj["style"][value["name"]][value["pos"]] = arr
                                                                    
                                                                    if( (obj && obj.style && obj.style.Pant && obj.style.Pant.F[1] === "R") &&
                                                                        (obj.style.Pant.F[0] === "F-DP" || obj.style.Pant.F[0] === "F-SP")
                                                                    ){
                                                                        obj.style.Pant.F[1] = "C"
                                                                    }
                                                                    if( (obj && obj.style && obj.style.Pant && obj.style.Pant.F[2] === "G") &&
                                                                        (obj.style.Pant.F[0] === "F-NP")
                                                                    ){
                                                                        obj.style.Pant.F[2] = "H"
                                                                    }


                                                                    setLoad(true)
                                                                    if(!checkCategory(category)){
                                                                        if(category === "formal"){
                                                                            if(value["name"] === "Shirt"){

                                                                                if(value["pos"] === "F"){
                                                                                    setImgState(1)
                                                                                    setLoad(false)
                                                                                }else{
                                                                                    setImgState(2)
                                                                                    setLoad(false)
                                                                                }
                                                                            } else {
                                                                                if(value["pos"] === "F"){
                                                                                    setImgState(3)
                                                                                    setLoad(false)
                                                                                }else{
                                                                                    setImgState(4)
                                                                                    setLoad(false)
                                                                                }
                                                                                
                                                                            }
                                                                        } else {
                                                                            if(value["pos"] && value["pos"] === "F"){
                                                                                setImgState(1)
                                                                                setLoad(false)
                                                                            }else {
                                                                                setImgState(2)
                                                                                setLoad(false)
                                                                            }
                                                                        }
                                                                        
                                                                        
                                                                    } else{

                                                                    
                                                                    // console.log("SEL Style, ", obj)
                                                                        if(value["name"] === "Coat"){

                                                                            if(value["pos"] === "F"){
                                                                                setImgState(1)                             
                                                                            } else {
                                                                                setImgState(2)
                                                                            }   
                                                                            setLoad(false)
                                                                        }
                                                                        if(value["name"] === "Shirt"){
                                                                            // setLoad(true)
                                                                            setImgState(3)
                                                                            setLoad(false)                                                          
                                                                        }
                                                                        
                                                                        if(value["name"] === "Vest"){
                                                                            // setLoad(true)
                                                                            var i = 0
                                                                            if(sel && sel.types && sel.types.includes("Shirt")){
                                                                                i = 6
                                                                            }else{
                                                                                i = 5
                                                                            }
                                                                            setImgState(i)
                                                                            setLoad(false) 
                                                                        }

                                                                        if(value["name"] === "Pant"){
                                                                            var i = 0
                                                                            if(sel && sel.types && sel.types.includes("Shirt")){
                                                                                i = 4
                                                                            }else{
                                                                                i = 3
                                                                            }
                                                                            // setLoad(true)
                                                                            if(value["pos"] === "F"){
                                                                                setImgState(i)                                                        
                                                                            } else {
                                                                                setImgState(i+1)
                                                                            }
                                                                            setLoad(false)   
                                                                        }
                                                                    }
                                                                    console.log("from Style :", sel)
                                                                    await getStyleImg()
                                                                    
                                                                }
                                                            }
                                                        }}
                                                        key={k1}
                                                    >
                                                        {/* <img src={val1.img} className='s-f-img' /><br /> */}
                                                        {/* {val1.img} */}
                                                        {/* <svg className='s-f-img'>
                                                        
                                                        {val1.img}
                                                        </svg><br /> */}
                                                        <ReactSVG src={val1.img} className={`s-f-img ${(sel.style?.[value.name]?.[value.pos]?.[value.n] === val1.value && !(jodh && value["type"]=== "Lapel") ) ? `filter_svg` : ''} `}
                                                        />
                                                        <div className={sel.style?.[value.name]?.[value.pos]?.[value.n] === val1.value ? 'sel_style_txt' : ''}>
                                                            {val1.name}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default StyleP;
