import { createRef, useContext, useEffect, useRef, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import proc3 from '../assets/proc3.jpg'
import './measurements.css'
import { useNavigate } from 'react-router-dom';

import {getState} from '../Firebase/auth'

import length_eng from '../assets/measurments/VIDEOS/length_eng.mp4'
import length_hin from '../assets/measurments/VIDEOS/length_hin.mp4'

import chest_eng from '../assets/measurments/VIDEOS/chest_eng.mp4'
import chest_hin from '../assets/measurments/VIDEOS/chest_hin.mp4'

import stomach_eng from '../assets/measurments/VIDEOS/stomach_eng.mp4'
import stomach_hin from '../assets/measurments/VIDEOS/stomach_hin.mp4'

import hip_eng from '../assets/measurments/VIDEOS/hip_eng.mp4'
import hip_hin from '../assets/measurments/VIDEOS/hip_hin.mp4'

import shoulder_eng from '../assets/measurments/VIDEOS/shoulder_eng.mp4'
import shoulder_hin from '../assets/measurments/VIDEOS/shoulder_hin.mp4'

import sleeve_eng from '../assets/measurments/VIDEOS/sleeve_eng.mp4'
import sleeve_hin from '../assets/measurments/VIDEOS/sleeve_hin.mp4'

import neck_eng from '../assets/measurments/VIDEOS/neck_eng.mp4'
import neck_hin from '../assets/measurments/VIDEOS/neck_hin.mp4'

import bisep_eng from '../assets/measurments/VIDEOS/bisep_eng.mp4'
import bisep_hin from '../assets/measurments/VIDEOS/bisep_hin.mp4'


// lower Body
import leg_eng from '../assets/measurments/VIDEOS/pant_eng.mp4'
import leg_hin from '../assets/measurments/VIDEOS/pant_hin.mp4'

import waist_eng from '../assets/measurments/VIDEOS/waist_eng.mp4'
import waist_hin from '../assets/measurments/VIDEOS/waist_hin.mp4'

import circle_eng from '../assets/measurments/VIDEOS/circle_eng.mp4'
import circle_hin from '../assets/measurments/VIDEOS/circle_hin.mp4'

// import hip_eng from '../assets/measurments/VIDEOS/hip_eng.mp4'
// import hip_hin from '../assets/measurments/VIDEOS/hip_hin.mp4'

import thigh_eng from '../assets/measurments/VIDEOS/thigh_eng.mp4'
import thigh_hin from '../assets/measurments/VIDEOS/thigh_hin.mp4'

import bottom_eng from '../assets/measurments/VIDEOS/bottom_eng.mp4'
import bottom_hin from '../assets/measurments/VIDEOS/bottom_hin.mp4'

////
import play from '../assets/icon/play_y.svg'
import pause from '../assets/icon/pause_y.svg'

import view from '../assets/measurments/View.png'
import arr_l from '../assets/ALeft.png'
import arr_r from '../assets/ARight.png'
import { addData, handleFireBaseUpload } from '../Firebase/firestore';
import { AuthContext } from '../Firebase/AuthProvider';
import { db } from '../firebase';
// import Slider from 'bootstrap-slider';

import contact from '../assets/icon/contact.png'
import { Types } from '../Data';
function CustomM({M, setM, setLoading, setPicModal, mState, setMState, setSvd, cat}) {

    
    const { user } = useContext(AuthContext);

    const [final, setFinal] = useState(JSON.parse(sessionStorage.getItem("sel")))
    
    const [mvideos, setVideos] = useState({
        1:[ {"src":[length_eng, length_hin], "name":"Length"},
            {"src":[chest_eng, chest_hin], "name":"Chest"},
            {"src":[stomach_eng, stomach_hin], "name":"Stomach"},
            {"src":[hip_eng, hip_hin], "name":"Hip"},
            {"src":[shoulder_eng, shoulder_hin], "name":"Shoulder"},
            {"src":[sleeve_eng, sleeve_hin], "name":"Sleev Length"},
            {"src":[neck_eng, neck_hin], "name":"Neck"},
            {"src":[bisep_eng, bisep_hin], "name":"Bisep"}],
        2:[{"src":[leg_eng,leg_hin ], "name":"Leg Length"},
            {"src":[waist_eng,waist_hin ], "name":"Waist"},
            {"src":[circle_eng,circle_hin ], "name":"Crotch"},
            {"src":[hip_eng,hip_hin ], "name":"Hip"},
            {"src":[thigh_eng,thigh_hin ], "name":"Thighs"},
            {"src":[bottom_eng,bottom_hin ], "name":"Knees"}
        ],
       0 : [""]
    })

    const [lang, setLang] = useState(0)

    const [currVideo , setCurrVideo] = useState({
        ...mvideos[1][0], ...{"src":mvideos[1][0]["src"][lang], "id":0}
    })
    const vidCtrl = useRef(null)
    const [playing, setPlaying] = useState(false)

    useEffect(()=>{
        if(!playing && mState !=0){
            vidCtrl.current.pause();
        }else if(mState != 0){
            vidCtrl.current.play();
        }
    }, [playing])

    useEffect(()=>{

        if(mState>0){
            
            setCurrVideo( {...currVideo, ...{"src": mvideos[mState][currVideo["id"]][lang] } })
       }
    }, [lang])

    useEffect(()=>{
        if(mState > 0){
            setCurrVideo({
                ...mvideos[mState][0], ...{"src":mvideos[mState][0]["src"][lang], "id":0}
            }
            )
        }
    }, [mState])
    
    const notify = () => toast.error('Please add all Details', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });

    const [inpts, setInputs] = useState()
    const navigate = useNavigate();
    
    // var mySlider = new Slider(newItem, {
    //     // initial options object
    //     step: 1,    
    //     min: 1,
    //     max:3,
    //     value: 1,
    //     tooltip: 'hide'
    //  });

    const [l1, setL1] = useState()
    const [l2, setL2] = useState()
    const [l3, setL3] = useState()
    const [l4, setL4] = useState()
    const [l5, setL5] = useState()
    const [l6, setL6] = useState()

    const [u1, setU1] = useState()
    const [u2, setU2] = useState()
    const [u3, setU3] = useState()
    const [u4, setU4] = useState()
    const [u5, setU5] = useState()
    const [u6, setU6] = useState()
    const [u7, setU7] = useState()
    const [u8, setU8] = useState()

    const [side, setSide] = useState()
    const [pSide, setPside] = useState()

    const [front, setfront] = useState()
    const [pfront, setPfront] = useState()

    useEffect(()=>{
        if (!side) {
            setPside(undefined)
          return
      }
          const objectUrl = URL.createObjectURL(side)
          setPside(objectUrl)
    
          // free memory when ever this component is unmounted
          return () => URL.revokeObjectURL(objectUrl)
      }, [side])

      useEffect(()=>{
        if (!front) {
            setPfront(undefined)
          return
      }
          const objectUrl = URL.createObjectURL(front)
          setPfront(objectUrl)
    
          // free memory when ever this component is unmounted
          return () => URL.revokeObjectURL(objectUrl)
      }, [front])
    // const inpts = useRef([]);

    useEffect(()=>{
        
        // console.log("calling use effect from measurements")
        setInputs( [createRef(""), createRef(""), createRef(""), createRef(""),
        createRef(""), createRef(""), createRef(""), createRef(""),
        createRef(""), createRef(""), createRef(""), createRef("")] )
    }, [])

    // const [mState, setMState] = useState(0)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);

    const addMeasurementProfile = async(measure)=>{
        try{
            let usrid = user.email
            if(!usrid){
                usrid = user.phoneNumber
            }

            const collection = db.collection('Users').doc('measurements').collection(usrid)

            await addData( collection , "", measure)

            console.log("Measure added", measure)
        }catch(e){
            console.log(e)
        }

    }

    const addToCart=async(data)=>{
        try{
            sessionStorage.removeItem("sel")
            let currC = localStorage.getItem("sel")
            
            await addMeasurementProfile( data['measurements'] )


            if( Object.hasOwn(data, "id") ){
                let local = JSON.parse( currC)
                local.forEach((k, i)=>{
                    if(k["id"] === data["id"]){
                        local[i] = data
                    }
                })
                localStorage.setItem("sel", JSON.stringify(local))

                // console.log("updated Item")
                
            }else{

                if(currC && JSON.parse(currC).length > 0){
                    let upC = JSON.parse(currC)
                    // console.log("curr",upC)
                    let c = upC[upC.length - 1]["id"] + 1
                    data["id"] = c
                    upC.push(data)
                    
                    localStorage.setItem("sel", JSON.stringify(upC))
                }else{
                    data["id"] = 1
                    localStorage.setItem("sel", JSON.stringify([data]))
                }
            }
            // console.log("data", JSON.parse(localStorage.getItem("sel")))
            navigate('/cart');
        } catch(e){
            console.log("from cart addition in CM", e)
        }
    }

    return ( 
        <div className="c-m hori_center">

                <div>
                    {/* <button onClick={notify}>Notify!</button> */}
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
            </div>

            {mState == 0 && inpts && inpts.length > 0 ? (
                <div style={{width:"100%"}}>
                <div className='page_1_left'>
                    <div className="txt-font hori_center"
                        style={{"width":"fit-content"}}
                    >

                        Measurement
                    </div>
                    <div className="c-m-des1 hori_center">
                        Accurate measurements help us deliver the best experience you can have.
                        Experience the convenience of measuring yourself at home! We’ll guide you every step of the way—just follow our helpful videos to achieve the perfect fit quickly and effortlessly.
                        <div style={{fontStyle:"italic", fontWeight:"bold", textAlign:"center"}} >"You dont need a Taior , All you need is a Measuring tape"</div>
                    
                    </div>

                    
                    
                    
                    <div className='c-m-input0 hori_center' style={{display:"flex", flexDirection:"column" ,gap:"20px"}}>
                        <div className='txt-font1 gen-mob FIT_W'> <b>General</b> </div>
                        <div className='input-wrap'>
                            <div className='name-mob'>Name <label style={{"color":"red"}}><b>*</b></label> </div>
                            <input className='c-m-i0' placeholder='Your Name' ref={inpts[0]}
                                onChange={()=>{
                                    // console.log(name.current.value);
                                }}
                            />
                        </div>
                        <div style={{display:"flex", gap:"20px"}}>
                            <div className='input-wrap hei-mob'>
                                <div>Height (ft) <label style={{"color":"red"}}><b>*</b></label> </div>
                                <input className='c-m-i2' placeholder='Height' ref={inpts[1]} required
                                    onChange={()=>{
                                        // console.log(inpts[1].current.value);
                                    }}
                                />
                            </div>
                            
                            <div className='input-wrap hei-mob'>
                                <div>Weight (kg) <label style={{"color":"red"}}><b>*</b></label></div>
                            <input className='c-m-i2 ' placeholder='Weight' ref={inpts[2]} required
                                onChange={()=>{
                                    // console.log(inpts[2].current.value);
                                }}
                            /></div>

                            <div className='input-wrap hei-mob '>
                                <div><label>{"Age"}</label></div>
                            <input className='c-m-i2' placeholder='Age' ref={inpts[3]} required
                                onChange={()=>{
                                    // console.log(inpts[3].current.value);
                                }}
                            /></div>
                        </div>
                        <div className='file_m_p_1 '>
                                <div className='input-wrap '
                                    type=  "button"
                                    style={{width:"150px"}}
                                >
                                    <div><b>Front View Image</b>
                                    
                                    </div>
                                    
                                    <label style={{width:"70px", border:"1px solid black", textAlign:"center"}}>
                                        Upload
                                        <input id='front_file' className='' placeholder='Front View' type='file' style={{display:"none"}}
                                            onChange={(e)=>{setfront(e.target.files[0])}}/>
                                    </label>
                                    &nbsp; {front && <>{front.name}</>}



                                </div>

                                <div className='input-wrap'
                                    type=  "button"
                                    style={{width:"150px"}}
                                >
                                <div><b>Side View Image</b>
                                </div>
                                
                                <label style={{width:"70px", border:"1px solid black", textAlign:"center"}}>
                                        Upload
                                        <input placeholder='Side View' type='file' style={{display:"none"}}
                                            onChange={(e)=>{setSide(e.target.files[0])}}/>
                                </label>
                                &nbsp; {side && <>{side.name}</>}
                                </div>
                        </div>
                        {/* <div style={{}} className='FIT_W'>
                            <div className='c-m-b-c hori_center'>
                                

                            </div>
                        </div> */}
                        
                    </div>

                    
                </div>
                <div className={isMobile ? 'page_1_img hori_center' : 'page_1_img' }
                    style={{border:"1px solid #EAEAEA"}}
                >
                    <img src={view} style={{width:"100%", height:'100%', objectFit:"contain"}}/>
                    <div className='txt-small1 hori_center' style={{textAlign:"center", width:"90%"}}> 
                        Front and Side view images of self, helps us to identify the body type and 
                        get you the perfect fit for the clothig. Please follow the instruntions in the 
                        image to take the photo. It is not necessary to add your face into the image. 
                    </div>

                    <button className='Butt1 hori_center' style={{width:"100%"}}
                        onClick={()=>{
                            setSvd( {id:1} )
                        }}
                    >
                            <span>Select From Saved Measurments</span>
                    </button>
                </div>
                </div>
            )
                
            :
            (<div className='c-m-body'>
                <div className='c-m-left'>

                
                    <div className="txt-font head1">
                        Enter your Measurement
                    </div>
            

                    <div className="c-m-des">
                    Experience the convenience of measuring yourself at home! We’ll guide you every step of the way—just follow our helpful videos to achieve the perfect fit quickly and effortlessly.
                    Contact us if you have any questions about entering your measurments                    
                    <br/>
                    <a href="+918871081395" style={{textDecoration:"none", color:"black"}}>
                        <label style={{margin:"0"}} type="button">
                        <img src={contact} style={{width:"20px"}}/> <b>Get Help</b>
                    </label>
                    </a>
                    </div>

                    <div className='c-m-input'>
                        <div className='c-m-i-box '>
                            <div className='txt-font1 FIT_W hori_center'
                                style = {{"marginBottom":"20px"}}
                            >
                                
                              <b>  {mState === 1 ? "Upper Body" : mState === 2 ? "Lower Body" : ("") }</b>
                            </div>

                            {mState == 1 && inpts && inpts.length > 0 ?
                            (<div>
                                {/* ///////////////// Mobile Measurements */}
                                {isMobile ? (
                                    
                                    <div style={{columns:"2"}}>
                                    
                                    <div className='input-wrap'>
                                        <div>Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 0)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 0){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][0], ...{"src":mvideos[mState][0]["src"][lang], "id":0}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Length' onChange={(e)=>{setU1(e.target.value)}} value={u1 ? u1 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Chest Around
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 1)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 1){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][1], ...{"src":mvideos[mState][1]["src"][lang], "id":1}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Chest Around' onChange={(e)=>{setU2(e.target.value)}} value={u2 ? u2 : ""} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>

                                    <div className='input-wrap'>
                                        <div
                                            
                                        >Stomach
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 2)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 2){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][2], ...{"src":mvideos[mState][2]["src"][lang], "id":2}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "right":"0"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Stomach' onChange={(e)=>{setU3(e.target.value)}} value={u3 ? u3 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div
                                            
                                        >Hip
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={currVideo["id"] ==3 ? pause : play }
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 3){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][3], ...{"src":mvideos[mState][3]["src"][lang], "id":3}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "right":"0"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Hip' onChange={(e)=>{setU4(e.target.value)}} value={u4 ? u4 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Shoulder Width
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 4)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 4){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][4], ...{"src":mvideos[mState][4]["src"][lang], "id":4}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1' placeholder='Shoulder Width' onChange={(e)=>{setU5(e.target.value)}} value={u5 ? u5 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Sleeve Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 5)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 5){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][5], ...{"src":mvideos[mState][5]["src"][lang], "id":5}
                                                    }
                                                    )
                                                }}
                                                
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />

                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1' placeholder='Sleeve Length' onChange={(e)=>{setU6(e.target.value)}} value={u6 ? u6 : ""} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Neck
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 6)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 6){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][6], ...{"src":mvideos[mState][6]["src"][lang], "id":6}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Neck' onChange={(e)=>{setU7(e.target.value)}} value={u7 ? u7 : ""} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Bisep
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 7)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 7){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][7], ...{"src":mvideos[mState][7]["src"][lang], "id":7}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Bisep' onChange={(e)=>{setU8(e.target.value)}} value={u8 ? u8 : ""} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>

                                </div>
                                ) 
                                
                                : (<>
                                    <div className='c-m-i-r'>
                                    
                                    <div className='input-wrap mob-input mob-right-d3'>
                                        <div>Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 0)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 0){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][0], ...{"src":mvideos[mState][0]["src"][lang], "id":0}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Length' onChange={(e)=>{setU1(e.target.value)}} value={u1 ? u1 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-l2'>
                                        <div>Chest Around
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 1)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                                    if(currVideo && currVideo["id"] != 1){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }
                                                    
                                                    setCurrVideo({
                                                        ...mvideos[mState][1], ...{"src":mvideos[mState][1]["src"][lang], "id":1}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Chest Around' onChange={(e)=>{setU2(e.target.value)}} value={u2 ? u2 : ""} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>

                                    <div className='input-wrap mob-input mob-right-d2'>
                                        <div
                                            
                                        >Stomach
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 2)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 2){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][2], ...{"src":mvideos[mState][2]["src"][lang], "id":2}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "right":"0"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Stomach' onChange={(e)=>{setU3(e.target.value)}} value={u3 ? u3 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-d2'>
                                        <div
                                            
                                        >Hip
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 3)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 3){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][3], ...{"src":mvideos[mState][3]["src"][lang], "id":3}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "right":"0"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1 stomach-mob' placeholder='Hip' onChange={(e)=>{setU4(e.target.value)}} value={u4 ? u4 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='c-m-i-r'>
                                
                                    <div className='input-wrap mob-input mob-input-d1'>
                                        <div>Shoulder Width
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 4)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 4){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][4], ...{"src":mvideos[mState][4]["src"][lang], "id":4}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i1' placeholder='Shoulder Width' onChange={(e)=>{setU5(e.target.value)}} value={u5 ? u5 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-input-l1'>
                                        <div>Sleeve Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 5)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 5){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][5], ...{"src":mvideos[mState][5]["src"][lang], "id":5}
                                                    }
                                                    )
                                                }}
                                                
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />

                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1' placeholder='Sleeve Length' onChange={(e)=>{setU6(e.target.value)}} value={u6 ? u6 : ""} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-l3'>
                                        <div>Neck
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 6)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 6){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][6], ...{"src":mvideos[mState][6]["src"][lang], "id":6}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Neck' onChange={(e)=>{setU7(e.target.value)}} value={u7 ? u7 : ""} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-l3'>
                                        <div>Bisep
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 7)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 7){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][7], ...{"src":mvideos[mState][7]["src"][lang], "id":7}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                            <input className='c-m-i1 ' placeholder='Bisep' onChange={(e)=>{setU8(e.target.value)}} value={u8 ? u8 : ""} />
                                            <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                </div>
                                </>)}
                                
                            </div>) : mState == 2 ?
                            (<div>
                                {isMobile ? 
                                (<div style={{columns:"2"}}>
                                    <div className='input-wrap'>
                                        <div>Leg Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 0)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 0){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][0], ...{"src":mvideos[mState][0]["src"][lang], "id":0}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Leg Length' onChange={(e)=>{setL1(e.target.value)}} value={l1 ? l1 : ""} id='jnf'/>
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Pants Waist
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 1)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 1){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][1], ...{"src":mvideos[mState][1]["src"][lang], "id":1}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Pants Waist' onChange={(e)=>{setL2(e.target.value)}} value={l2 ? l2 : ""} id='jnf' />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Circle
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 2)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 2){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][2], ...{"src":mvideos[mState][2]["src"][lang], "id":2}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Circle' onChange={(e)=>{setL4(e.target.value)}} value={l4 ? l4 : ""} id='jnf'/>
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Hip
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 3)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 3){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][3], ...{"src":mvideos[mState][3]["src"][lang], "id":3}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Hip' onChange={(e)=>{setL3(e.target.value)}} value={l3 ? l3 : ""} id='jnf' />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Thigh
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 4)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 4){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][4], ...{"src":mvideos[mState][4]["src"][lang], "id":4}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Thigh' onChange={(e)=>{setL5(e.target.value)}} value={l5 ? l5 : ""} id='jnf'/>
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap'>
                                        <div>Bottom
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 5)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 5){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][5], ...{"src":mvideos[mState][5]["src"][lang], "id":5}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Bottom' onChange={(e)=>{setL6(e.target.value)}} value={l6 ? l6 : ""} id='jnf'/>
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    
                                </div>) 
                                
                                : (<>
                                
                                <div className='c-m-i-r1'>
                                    <div className='input-wrap mob-input mob-input-l1'>
                                        <div>Leg Length
                                            <label style={{"color":"red"}}><b>*</b></label>
                                            <img src={ ((playing && currVideo["id"] === 0)) ? pause : play}
                                            type = "button"
                                            onClick={()=>{
                                                if(currVideo && currVideo["id"] != 0){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }    
                                                setCurrVideo({
                                                        ...mvideos[mState][0], ...{"src":mvideos[mState][0]["src"][lang], "id":0}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Leg Length' id='jnf'  onChange={(e)=>{setL1(e.target.value)}} value={l1 ? l1 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-l2'>
                                        <div>Pants Waist
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 1)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 1){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][1], ...{"src":mvideos[mState][1]["src"][lang], "id":1}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Pants Waist' id='jnf' onChange={(e)=>{setL2(e.target.value)}} value={l2 ? l2 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-input-d1'>
                                        <div>Circle
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 2)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 2){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][2], ...{"src":mvideos[mState][2]["src"][lang], "id":2}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Circle' id='jnf'  onChange={(e)=>{setL4(e.target.value)}} value={l4 ? l4 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='c-m-i-r1'>
                                <div className='input-wrap mob-input mob-right-l3'>
                                        <div>Hip
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 3)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 3){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][3], ...{"src":mvideos[mState][3]["src"][lang], "id":3}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Hip' id='jnf' onChange={(e)=>{setL3(e.target.value)}} value={l3 ? l3 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-d2'>
                                        <div>Thigh
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 4)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 4){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][4], ...{"src":mvideos[mState][4]["src"][lang], "id":4}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Thigh' id='jnf'  onChange={(e)=>{setL5(e.target.value)}} value={l5 ? l5 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                    <div className='input-wrap mob-input mob-right-d3'>
                                        <div>Bottom
                                        <label style={{"color":"red"}}><b>*</b></label>
                                        <img src={ ((playing && currVideo["id"] === 5)) ? pause : play}
                                        type = "button"
                                        onClick={()=>{
                                            if(currVideo && currVideo["id"] != 5){
                                                        setPlaying(true)
                                                    }else{
                                                        setPlaying(!playing)
                                                    }        
                                            setCurrVideo({
                                                        ...mvideos[mState][5], ...{"src":mvideos[mState][5]["src"][lang], "id":5}
                                                    }
                                                    )
                                                }}
                                                style={{"width":"20px", "marginLeft":"10px"}}
                                            />
                                        </div>
                                        <div style={{"position":"relative"}}>
                                        <input className='c-m-i21' placeholder='Bottom' id='jnf' onChange={(e)=>{setL6(e.target.value)}} value={l6 ? l6 : ""} />
                                        <label style={{"position":"absolute", "bottom":"0", "fontSize":"1.2rem", "fontWeight":"bold", "opacity":"0.7", "margin":"0", "right":"10px"}}>in</label>
                                        </div>
                                    </div>
                                </div>

                                </>)}   
                                
                            </div>) : (<></>)}

                        </div>
                    </div>
                </div>

                <div className='c-m-right'>
                    <div className='lang_mobile'>
                        <div className='lang hori_center'>
                        <label className={ lang === 1 ? "lang_ind" :  "lang_ind lang_s"}
                            type="button"
                            onClick={()=>{setLang(0)}}
                        >English</label>
                        <label 
                            type="button"
                            className={ lang === 0 ? "lang_ind" :  "lang_ind lang_s"}
                            onClick={()=>{setLang(1)}}
                        >Hindi</label>
                        </div>
                    </div>
                    <div className="c-m-video both_center">
                            <video className='c-m-img' ref={vidCtrl}
                                src={ currVideo["src"] } controls playsInline
                                // poster='https://firebasestorage.googleapis.com/v0/b/maviinvi.appspot.com/o/LOGO%2Fm_m.png?alt=media&token=5ff42c9c-6663-4fce-afd7-890b2c4556ac'
                            >
                            </video>
                            {/* <img src={proc3} className='c-m-img'/> */}
                            
                            <div className='c-m-v-des'>
                                {/* <img  src  = {arr_l} style={{"width":"25px", "position":"absolute", "left":"0"}}
                                    
                                /> */}
                                <div
                                    className='c-m-v-info hori_center'
                                >
                                <div > <b> Measurement {currVideo["name"]} length </b></div>
                                <div style={{"fontSize":"0.7rem"}} > <span style={{"color":"red"}} >*</span> The Video contains instructions you should follow to get Measured at home easily. Please add the measurments carefully. The final Product will depend on the values you add</div>
                                </div>
                                {/* <img  src  = {arr_r} style={{"width":"25px","position":"absolute", "right":"0"}}/> */}
                            </div>

                    </div>
                </div>
            </div>
            
            )}
            

            
                  
            

            <div className="c-m-buttons hori_center proceed-but2">   

                { mState === 0 ? "" : <button className="Butt1 "
                    onClick={()=>{
                        setMState((prev)=>prev===0 ? prev = 0 : prev-1)
                    }}
                ><span>Go Back</span></button>}
                <button className="Butt1"
                    onClick={async()=>{
                        if(mState == 0){
                            if(
                                (!inpts[0].current.value ||
                                !inpts[1].current.value ||
                                !inpts[2].current.value )
                            ){
                                notify()
                            } else{
                            // console.log("this is ok")
                            setLoading(true)
                            var F = "";
                            var S = "";

                            if(front){
                                const F = await handleFireBaseUpload((new Date()).getTime().toString(), front, 'User/front')
                            }
                            if(side){
                                const S = await handleFireBaseUpload((new Date()).getTime().toString(), side, 'User/side')
                            }
                            setLoading(false)
                            setFinal({...final, ...{
                                
                                
                                "measurements" : {
                                    "name" : inpts[0].current.value,
                                    "type":1,
                                    "height":inpts[1].current.value,
                                    "weight":inpts[2].current.value,
                                    "age":inpts[3].current.value,
                                    "front_view" :  F,
                                    "side_view" :  S,
                                }
                            }
                        })
                            // console.log("this is also ok")
                            // console.log( inpts[1].current.value, inpts[2].current.value, inpts[3].current.value,inpts[4].current.value)
                            // console.log("this is also ok")
                            
                                if(cat === 2){ 
                                    setMState((prev)=>prev===2 ? prev=2 : prev+1)
                                } else if( cat === 1 ){
                                    setMState(2)
                                } else {
                                    setMState(1)
                                }
                            
                            }
                        }else if(mState == 1){
                            
                            const up_data = {...final, ...{"measurements":{...final.measurements, ...{
                                "upper_body":{
                                    "sleeve_length": u6 ? u6 :0,
                                    "chest_around":u2? u2 : 0 ,
                                    "neck":u7? u7 : 0 ,
                                    "shoulder_width":u5? u5 : 0 ,
                                    "stomach":u3? u3 : 0 ,
                                    "length":u1? u1 : 0 ,
                                    "hip":u4? u4 : 0 ,
                                    "bisep":u8? u8 : 0 } 
                                    }
                                    }
                                }
                            }
                            setFinal(up_data)

                            if(cat === 2){ 
                                setMState((prev)=>prev===2 ? prev=2 : prev+1)
                            } else {
                                addToCart( up_data )
                            } 
                            // console.log(inpts[5].current.value,inpts[6].current.value,inpts[7].current.value,inpts[8].current.value,inpts[9].current.value,inpts[10].current.value)
                        }else{
                            
                            
                            let data = {...final}
                            data["measurements"]["lower_body"] = {
                                "leg_length":l1 ? l1 : 0,
                                "waist":l2 ? l2 : 0,
                                "hip":l3 ? l3 : 0,
                                "circle":l4 ? l4 : 0,
                                "thigh":l5 ? l5 : 0,
                                "bottom":l6 ? l6 : 0
                            }
                            // console.log(L1.current.value,L2.current.value,L3.current.value,L4.current.value)
                            addToCart( data )
                        }
                        
                        
                        
                    }}
                >
                   <span>{mState===2 ? "Checkout" : "Proceed"}</span></button>
            </div>


            
        </div> 
    );
}

export default CustomM;